<template>
    <div class="address-list">
        <van-address-list
            class="list"
            v-model="chosenAddressId"
            :list="list"
            default-tag-text="默认"
            @add="onAdd"
            @edit="onEdit"
            @select="onSelect"
        />
    </div>
</template>

<script>
import areaList from "@/assets/area";

export default {
    name: "AddressList",
    data() {
        return {
            chosenAddressId: 1,
            list: [],
            areaList,
        };
    },
    methods: {
        onAdd() {
            this.$router.push("/addredit");
        },
        onEdit(item) {
            console.log(item);
            sessionStorage.setItem("addrInfo", JSON.stringify(item))
            this.$router.push("/addredit");
        },
        onSelect(item) {
            const path = sessionStorage.getItem("chooseAddr");
            if (path) {
                sessionStorage.setItem("chooseAddr", JSON.stringify(item));
                this.$router.push({ path });
            }
            console.log(item);
        },
        async getAddr() {
            const res =  await axios.get("/get_my_addr");
            if (res.data.length === 0) {
                this.$router.push("/addredit");
            } else {
                const listData = res.data.map(item => ({ ...item, address: item.province + item.city + item.area + item.detail, isDefault: item.isDefault ? 1 : 0 }))
                this.list = listData;
            }
        }
    },
    
    created() {
        this.getAddr();
    }
};
</script>

<style lang="less">
.address-list {
    background-color: rgb(247,248,250);
    min-height: 100vh;

    .van-radio__icon {
        display: none;
    }
    /deep/ .van-radio__label {
        margin-left: 0px !important;
    }
    .van-address-item:active {
        background-color: #eee;
    }
    .van-cell {
        background: none;
    }
}
</style>
